import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "content", "icon" ]

  toggle() {
    this.contentTarget.classList.toggle("collapsed");
    this.iconTarget.classList.toggle("collapsed");
  }
}
