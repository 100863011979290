import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = {
    duration: { type: Number, default: 5000 }
  }

  initialize() {
    this.element.setAttribute('duration', this.durationValue);
    this.element.toast();
  }
}
