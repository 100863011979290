import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.element.removeAttribute("loading");
    this.element.addEventListener("click", this.showButtonLoadingSpinner.bind(this));
  }
  
  disconnect() {
    this.element.removeAttribute("loading");
    this.element.removeEventListener("click", this.showButtonLoadingSpinner.bind(this));
  }

  // Checks validity on closest form to prevent displaying a loading state when browser validation fails.
  showButtonLoadingSpinner(event) {
    const form = this.element.closest('form');

    if (form && !form.checkValidity()) {
      event.preventDefault();
      return;
    }

    this.element.setAttribute("loading", true);
  }
}
