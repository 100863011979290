import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    /* find the <a> element in the base shadow dom and
     * add the data-attribute `data-turbo-method="delete"` to
     * instruct turbo to clear the cache on logout and reload the page.
     * This is a workaround as shoelace does not support adding data-attributes
     * to the base element. Element is `data-turbo-permanent`, so only the first
     * connecting controller updates the attribute. */
    this.element.shadowRoot.querySelector('[part~="base"]').setAttribute('data-turbo-method', 'delete');
    // The controller can now safely be disconnected as it has served its purpose.
    this.disconnect();
  }
}
