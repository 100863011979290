import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // Add a click listener to open the mobile navbar (aside).
    const navbarOpenBtn = document.querySelector('#mobile-navbar-open-btn');

    navbarOpenBtn.addEventListener('click', function(_event) {
      document.querySelector("#mobile-navbar-drawer").show();
    });
  }
}
